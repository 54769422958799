<template>
  <div>
    <heading-main>2. Soak Seeds in a Drum</heading-main>
    <error-alert v-if="errorAlert">{{ errorAlert }}</error-alert>

    <div class="card mt-3">
      <div class="card-header">Crates ready for soaking:</div>
      <div class="card-body">
        <div v-for="crate in crates" :key="crate.tracker">

          <h6>Crate: {{ crate.tracker }}</h6>
          <p>{{ crate.seeds }}</p>
          <router-link :to="{
                            name: 'soak-crate',
                            params: {
                                crateTracker: crate.tracker
                            }}">
            <button class="btn btn-block btn-sm btn-secondary align-middle">Soak Crate in a Drum</button>
          </router-link>
          <hr>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'SoakSeeds',
  components: { ErrorAlert, HeadingMain },
  data () {
    return {
      crates: [],
      errorAlert: null,
    }
  },
  created () {
    Backend.get('/api/ready-for-soaking-crates')
        .then(response => {
          this.crates = response.data.items
          this.errorAlert = null
        })
        .catch(error => {
          this.errorAlert = error.response.data.message
        })
  },
}
</script>

<style scoped>

</style>
