<template>
  <div>
    <heading-main>5. End Sowings</heading-main>
    <error-alert v-if="errorAlert">{{ errorAlert }}</error-alert>
    <div class="card mt-3">
      <div class="card-header">Ongoing Sowings:</div>
      <div class="card-body">
        <div v-for="sowing in sowings" :key="sowing.resourceId">
          <span>Sandbed: {{ sowing.sandbedName }}</span> <br>
          <span>Segment: {{ sowing.sandbedSegmentName }}</span> <br>
          <span>Started on: {{ sowing.start }}</span> <br>
          <p>Seeds: {{ sowing.crackedSeeds }}</p>
          <button class="btn btn-secondary btn-block btn-sm" @click="endSowing(sowing)">Mark as Ended</button>
          <hr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'EndSowings',
  components: { ErrorAlert, HeadingMain },
  data () {
    return {
      sowings: [],
      errorAlert: null,
    }
  },
  created () {
    this.getOngoingSowings()
  },
  methods: {
    endSowing (sowing) {
      Backend.post('/api/ended-sowings', {
        employeeNo: window.store.user.employeeNo,
        sowingId: sowing.resourceId,
      })
          .then(() => {
            this.getOngoingSowings()
          })
          .catch(error => {
            this.errorAlert = error.response.data.message
          })
    },
    getOngoingSowings () {
      Backend.get('/api/ongoing-sowings')
          .then(response => {
            this.sowings = response.data.items
            this.errorAlert = null
          })
          .catch(error => {
            this.errorAlert = error.response.data.message
          })
    }
  }
}
</script>

<style scoped>

</style>
