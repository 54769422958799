<template>
  <div class="logout">
    ... logging out ...
  </div>
</template>

<script>
import { Backend } from '@/utilities/Backend'

export default {
  name: 'Logout',
  mounted () {
    Backend.post('/logout')
        .then(() => {
          window.store.auth.isAuthenticated = false
          window.store.user.employeeName = ''
          window.store.user.employeeNo = ''
          this.$router.push({ name: 'Login', params: { routeMessage: 'Successfully logged out.' } })
        })
  },
}
</script>

<style scoped>

</style>
