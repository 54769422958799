<template>
    <div>
        <h4 class="mt-3"><slot></slot></h4>
        <hr>
    </div>
</template>

<script>
    export default {
        name: "HeadingMain"
    }
</script>

<style scoped>

</style>
