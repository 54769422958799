<template>
  <div>
    <heading-main>Discard uncracked seeds</heading-main>
    <div class="alert alert-dark">
      When discarding seeds, it will be assumed that the cracking in this location is finished.
      You will not be able to pick seeds for sowing anymore.
    </div>
    <error-alert v-if="errorAlert">{{ errorAlert }}</error-alert>
    <validation-errors :errors="validationErrors"></validation-errors>
    <div class="d-flex w-100 justify-content-between">
      <p>Location:</p>
      <p>{{ crackingLocation }}</p>
    </div>
    <div class="form-group row">
      <label class="col-3 col-form-label" for="weight">Weight of Seeds:</label>
      <div class="col-9">
        <input class="col form-control text-right" id="weight" placeholder="input weight here" v-model="weight">
      </div>
    </div>
    <button class="btn btn-block btn-primary" @click="discard">Discard Seeds & End Cracking</button>

  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import ValidationErrors from '@/components/Utilities/ValidationErrors'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'NewDiscard',
  components: { ErrorAlert, HeadingMain, ValidationErrors },
  props: {
    crackingLocation: String,
    crackingId: String,
  },
  data () {
    return {
      weight: null,
      errorAlert: null,
      validationErrors: [],
    }
  },
  methods: {
    discard () {
      Backend.post('/api/discards', {
        employeeNo: window.store.user.employeeNo,
        crackingId: this.crackingId,
        weight: this.weight,
      })
          .then(() => {
            this.$router.push({ name: 'seeding-management', params: { message: 'Seeds discarded.' } })
          })
          .catch(error => {
            this.errorAlert = error.response.data.message
            this.validationErrors = error.response.data.errors
          })
    }
  }
}
</script>

<style scoped>

</style>
