<template>
  <div>
    <heading-main class="mt-3">1. Gather Seeds form a Tree</heading-main>
    <router-link :to="{ name: 'gather-new-seeds'}">
      <button class="btn btn-block btn-primary">Gather New Seeds</button>
    </router-link>

    <div class="card mt-3">
      <div class="card-header">Gathered Seeds:</div>
      <div class="card-body">
        <error-alert v-if="gatheringsLoadingError">{{ gatheringsLoadingError }}</error-alert>
        <error-alert v-if="motherTreeLoadingError">{{ motherTreeLoadingError }}</error-alert>
        <div v-for="gathering in gatherings" :key="gathering.resourceId">
          <h6>Batch: {{ gathering.gathering.seedsBatchId }}</h6>
          <h6>From tree: {{ gathering.motherTree.farmerCode }} Tree No.: {{ gathering.motherTree.number }}</h6>
          <h6>Crates:</h6>
          <div class="d-flex w-100 justify-content-between" v-for="crate in gathering.gathering.crates"
               :key="crate.tracker">
            <span>Barcode: {{ crate.tracker }}</span>
            <span>{{ crate.seedsWeight }}</span>
          </div>
          <div class="d-flex w-100 justify-content-between mt-1">
            <h6>Total Weight:</h6>
            <h6>{{ gathering.gathering.totalWeight }}</h6>
          </div>
          <router-link :to="{
                        name: 'purchase-batch',
                        params: {
                            batchNo: gathering.gathering.seedsBatchId,
                            farmerCode:gathering.motherTree.farmerCode,
                             weight: gathering.gathering.totalWeight}}">
            <button class="btn btn-block btn-sm btn-secondary mt-2">Buy this batch</button>
          </router-link>
          <hr>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'GatherSeeds',
  components: { ErrorAlert, HeadingMain },

  data () {
    return {
      gatherings: [],
      motherTreeLoadingError: null,
      gatheringsLoadingError: null,
    }
  },

  created () {
    this.getGatherings()
  },

  methods: {
    getGatherings () {
      Backend.get('/api/gatherings')
          .then(response => {
            response.data.items.forEach(
                (gathering) => {
                  Backend.get(gathering.motherTreeHref)
                      .then(response => {
                        this.gatherings.push({
                          gathering: gathering,
                          motherTree: response.data,
                        })
                        this.motherTreeLoadingError = null
                      })
                      .catch(error => {
                        this.motherTreeLoadingError = error.response.data.message
                      })
                })
            this.gatheringsLoadingError = null
          })
          .catch(error => {
            this.gatheringsLoadingError = error.response.data.message
          })
    },
  }
}
</script>

<style scoped>

</style>
