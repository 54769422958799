<template>
  <div id="sandbeds">

    <heading-main>Register a new Sandbed</heading-main>
    <success-alert v-if="showSuccessAlert">Sandbed registered!</success-alert>
    <error-alert v-if="errorAlertMessage">{{ errorAlertMessage }}</error-alert>
    <validation-errors :errors="validationAlerts"></validation-errors>
    <div class="form-group">
      <label for="sandbedName">Name of new sandbed:</label>
      <input class="form-control" id="sandbedName" v-model="sandbedName">
    </div>
    <button class="btn btn-block btn-primary" @click="createSandbed">Create Sandbed</button>


    <heading-main class="mt-3">Registered Sandbeds:</heading-main>
    <loading-spinner v-if="showLoadingSandbeds">Loading sandbeds...</loading-spinner>
    <error-alert v-if="sandbedsLoadingError">{{ sandbedsLoadingError }}</error-alert>
    <ul class="list-group">
      <li class="card mb-2" v-for="sandbed in sandbeds" :key="sandbed.resourceId">
        <h6 class="d-flex w-100 justify-content-between card-header">
          <span class="m-0">Sandbed: {{ sandbed.name }}</span>
          <router-link :to="{ name: 'sandbed', params: { id: sandbed.resourceId } }">Edit</router-link>
        </h6>
        <div class="card-body py-2">
          <h6 class="mb-0">Segments:</h6>
          <div class="d-flex w-100 justify-content-between" v-for="segment in sandbed.segments"
               :key="segment.resourceId">
            <span class="">{{ segment.name }}</span>
            <span class=""> {{ segment.seeds }}</span>
          </div>
        </div>
      </li>
    </ul>

  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import ValidationErrors from '@/components/Utilities/ValidationErrors'
import SuccessAlert from '@/components/Utilities/SuccessAlert'
import LoadingSpinner from '@/components/Utilities/LoadingSpinner'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'Sandbeds',
  components: { ErrorAlert, HeadingMain, ValidationErrors, SuccessAlert, LoadingSpinner },

  data () {
    return {
      sandbedName: '',
      showSuccessAlert: false,
      errorAlertMessage: null,
      validationAlerts: [],
      showLoadingSandbeds: true,
      sandbedsLoadingError: null,
      sandbeds: [],
    }
  },

  created () {
    Backend.get('/api/sandbeds')
        .then(response => {
          this.showLoadingSandbeds = false
          this.sandbeds = response.data.items
        })
        .catch(error => {
          this.showLoadingSandbeds = false
          this.sandbedsLoadingError = error.response.data.message
          this.sandbeds = []
        })
    this.$root.headerText = 'Manage Sandbeds'
  },

  methods: {
    createSandbed: function () {
      Backend.post('/api/sandbeds', {
        name: this.sandbedName
      })
          .then(response => {
            this.sandbedName = ''
            this.showSuccessAlert = true
            this.errorAlertMessage = null
            this.validationAlerts = []
            this.showLoadingSandbeds = false
            this.sandbedsLoadingError = null
            this.sandbeds = response.data.items
          })
          .catch(error => {
            this.sandbedName = ''
            this.showSuccessAlert = false
            this.errorAlertMessage = error.response.data.message
            this.validationAlerts = error.response.data.errors
            this.showLoadingSandbeds = false
            this.sandbedsLoadingError = null
          })
    }
  }
}
</script>

<style scoped>

</style>
