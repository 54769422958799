<template>
  <div>
    <heading-main>Register a new Mother Tree</heading-main>
    <success-alert v-if="showRegistrationSuccess">Mother tree successfully registered.</success-alert>
    <error-alert v-if="showRegistrationError">{{ registrationErrorMessage }}</error-alert>
    <validation-errors :errors="validationErrors"></validation-errors>

    <div class="form-group">
      <label for="farmerCode">Farmer Code:</label>
      <input id="farmerCode" class="form-control" v-model="farmerCode">
    </div>
    <div class="form-group">
      <label for="number">Number of Tree:</label>
      <input id="number" class="form-control" v-model="number">
      <small class="form-text text-muted">The number of the tree at this farmer</small>
    </div>
    <div class="form-group">
      <label for="variety">Select Variety:</label>
      <select id="variety" class="form-control" v-model="variety">
        <option disabled value="">Please select variety...</option>
        <option v-for="varietyName in varieties" v-bind:value="varietyName" :key="varietyName">{{
            varietyName
          }}
        </option>
      </select>
    </div>
    <button class="btn btn-block btn-secondary" v-on:click="createTree">Register Mother Tree</button>


    <heading-main class="mt-3">Registered Mother Trees:</heading-main>
    <loading-spinner v-if="showLoadingMotherTrees">Loading mother trees...</loading-spinner>
    <error-alert v-if="showTreeLoadingError">{{ treeLoadingErrorMessage }}</error-alert>

    <ul class="list-group" v-for="tree in trees" :key="tree">
      <li class="list-group-item">
        <h5>Farmer: {{ tree.farmerCode }}</h5>
        <span>Tree No: {{ tree.number }}</span><br>
        <span>Variety: {{ tree.variety }}</span>
      </li>
    </ul>

  </div>

</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import ValidationErrors from '@/components/Utilities/ValidationErrors'
import LoadingSpinner from '@/components/Utilities/LoadingSpinner'
import SuccessAlert from '@/components/Utilities/SuccessAlert'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'MotherTrees',
  components: { ErrorAlert, HeadingMain, ValidationErrors, LoadingSpinner, SuccessAlert },

  data () {
    return {
      showRegistrationSuccess: false,
      showRegistrationError: false,
      registrationErrorMessage: '',
      validationErrors: [],
      farmerCode: '',
      number: '',
      variety: '',
      varieties: [],
      showLoadingMotherTrees: true,
      showTreeLoadingError: false,
      treeLoadingErrorMessage: '',
      trees: [],
    }
  },

  created () {
    Backend.get('/api/mother-trees')
        .then(response => {
          this.trees = response.data.items
          this.showLoadingMotherTrees = false
        })
        .catch(error => {
          this.showTreeLoadingError = true
          this.treeLoadingError = 'Internal error: ' + error.response.data.message
          this.showLoadingMotherTrees = false
        })

    Backend.get('/api/mother-tree-varieties')
        .then(response => this.varieties = response.data.items)
        .catch(error => {
          this.showRegistrationError = true
          this.registrationErrorMessage = 'Unable to load varieties. ' + error.response.data.message
        })
    this.$root.headerText = 'Manage Mother Trees'
  },

  methods: {
    createTree: function () {
      Backend.post('/api/mother-trees', {
        farmerCode: this.farmerCode,
        number: this.number,
        variety: this.variety
      })
          .then(response => {
                this.farmerCode = ''
                this.number = ''
                this.variety = ''
                this.trees = response.data.items
                this.showRegistrationError = false
                this.registrationErrorMessage = ''
                this.validationErrors = []
                this.showRegistrationSuccess = true
              }
          )
          .catch(error => {
            this.showRegistrationError = true
            this.registrationErrorMessage = error.response.data.message
            this.validationErrors = error.response.data.errors
            this.showRegistrationSuccess = false
          })
    }
  }
}
</script>

<style scoped>

</style>
