<template>
  <div>
    <heading-main>4. Sow or Discard Seeds</heading-main>
    <error-alert v-if="errorAlert">{{ errorAlert }}</error-alert>
    <div class="card mt-3">
      <div class="card-header">Ongoing Crackings:</div>
      <div class="card-body">
        <div v-for="cracking in crackings" :key="cracking.resourceId">
          <h6>Location: {{ cracking.crackingLocation }}</h6>
          <span>Started on: {{ cracking.start }}</span>
          <p>already Cracked: {{ cracking.crackedSeedsWeight }} grams</p>
          <router-link :to="{
                        name: 'sow-seeds',
                        params: {
                            crackingLocation: cracking.crackingLocation,
                            crackingId: cracking.resourceId,
                        }
                    }">
            <button class="btn btn-block btn-sm btn-secondary">
              Pick some seeds for sowing
            </button>
          </router-link>
          <router-link :to="{
                        name: 'discard-seeds',
                        params: {
                            crackingLocation: cracking.crackingLocation,
                            crackingId: cracking.resourceId,
                        }
                    }">
            <button class="btn btn-block btn-sm btn-danger mt-1">
              Discard uncracked seeds
            </button>
          </router-link>
          <hr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'SowSeeds',
  components: { ErrorAlert, HeadingMain },
  data () {
    return {
      crackings: [],
      errorAlert: null,
    }
  },
  created () {
    Backend.get('/api/ongoing-crackings')
        .then(response => {
          this.crackings = response.data.items
          this.errorAlert = null
        })
        .catch(error => {
          this.errorAlert = error.response.data.message
        })
  }
}
</script>

<style scoped>

</style>
