<template>
  <div class="login py-5">
    <info-alert class="mb-5" v-if="routeMessage">{{ routeMessage }}</info-alert>

    <img src="../../assets/Logo_Limbua.png" class="mx-auto d-block w-75 mb-5" alt="LIMBUA Nursery Seeding App">

    <heading-main>Login</heading-main>

    <error-alert v-if="showInvalidLoginAlert">Invalid login.</error-alert>

    <div class="form-group">
      <label for="email">E-Mail:</label>
      <input class="form-control" id="email" name="email" type="email" v-model="email" autofocus>
    </div>
    <div class="form-group">
      <label for="password">Password:</label>
      <input class="form-control" id="password" name="password" type="password" v-model="password">
    </div>

    <button class="btn btn-block btn-primary mt-3" @click="login">Login</button>

  </div>
</template>

<script>

import { Backend } from '@/utilities/Backend'
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import InfoAlert from '@/components/Utilities/InfoAlert'

export default {
  name: 'Login',
  props: ['routeMessage'],
  components: { ErrorAlert, HeadingMain, InfoAlert },
  data () {
    return {
      message: '',
      email: '',
      password: '',
      showInvalidLoginAlert: false,
    }
  },
  methods: {
    login () {
      this.showInvalidLoginAlert = false
      Backend.login('/login', {
        email: this.email,
        password: this.password
      })
          .then(response => {
            window.store.auth.isAuthenticated = true
            this.email = ''
            this.password = ''
            let message = 'Login successful.'
            this.$router.push({ name: 'seeding-management', params: { message } })
            window.store.user.employeeNo = response.data.employeeNo
            window.store.user.employeeName = response.data.employeeName
          })
          .catch(error => {
            window.store.auth.isAuthenticated = false
            this.message = error.response.data.message
            this.email = ''
            this.password = ''
            this.showInvalidLoginAlert = true
          })
    }
  }
}
</script>

<style scoped>

</style>
