import { createApp, reactive } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { Backend } from '@/utilities/Backend'
import 'bootstrap'

window.store = reactive({
  auth: {
    isAuthenticated: false
  },
  user: {
    employeeNo: '',
    employeeName: ''
  }
})

createApp(App).use(router).mount('#app')

Backend.init(() => {
  window.store.auth.isAuthenticated = false
  let routeMessage = 'Login expired, please login again.'
  router.push({ name: 'Login', params: { routeMessage } })
})
