<template>
  <div>
    <heading-main>Move to Cracking Location</heading-main>
    <validation-errors :errors="validationErrors"></validation-errors>
    <error-alert v-if="errorAlert">{{ errorAlert }}</error-alert>

    <div class="d-flex w-100 justify-content-between">
      <p>Drum No.:</p>
      <p>{{ drumNo }}</p>
    </div>
    <div class="form-group row">
      <label class="col-3 col-form-label" for="crackingLocation">Location:</label>
      <div class="col-9">
        <input class="col form-control text-right" id="crackingLocation" placeholder="input cracking location here"
               v-model="crackingLocation">
      </div>
    </div>
    <button class="btn btn-block btn-primary" @click="startCracking">Register Crackings</button>

  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import ValidationErrors from '@/components/Utilities/ValidationErrors'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'NewCracking',
  components: { ErrorAlert, HeadingMain, ValidationErrors },
  props: {
    drumNo: String,
  },
  data () {
    return {
      crackingLocation: '',
      validationErrors: [],
      errorAlert: null
    }
  },
  methods: {
    startCracking () {
      Backend.post('/api/crackings', {
        employeeNo: window.store.user.employeeNo,
        drumNo: this.drumNo,
        crackingLocation: this.crackingLocation
      })
          .then(() => {
            this.$router.push({ name: 'seeding-management', params: { message: 'Cracking registered.' } })
          })
          .catch(error => {
            this.errorAlert = error.response.data.message
            this.validationErrors = error.response.data.errors
          })
    }
  }

}
</script>

<style scoped>

</style>
