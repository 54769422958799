<template>
    <h2 class="mt-5">404 Page not found.</h2>
</template>

<script>
    export default {
        name: "404"
    }
</script>

<style scoped>

</style>
