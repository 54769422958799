<template>
  <div class="container-fluid p-0">

    <nav class="navbar navbar-dark bg-quarternary" v-if="store.auth.isAuthenticated">
      <span class="navbar-brand"><i class="fas fa-seedling"></i>{{ headerText }}</span>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navContent">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link :to="{ name: 'seeding-management' }" class="nav-link" data-toggle="collapse"
                         data-target="#navContent">Seeding Management
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'crates' }" class="nav-link" data-toggle="collapse" data-target="#navContent">
              Manage Crates
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'mother-trees' }" class="nav-link" data-toggle="collapse"
                         data-target="#navContent">Manage Mother Trees
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'sandbeds' }" class="nav-link" data-toggle="collapse" data-target="#navContent">
              Manage Sandbeds
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Logout' }" class="nav-link" data-toggle="collapse" data-target="#navContent">
              Logout
            </router-link>
          </li>
        </ul>
      </div>
    </nav>

    <div class="container pb-3">
      <router-view></router-view>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      store: window.store,
      headerText: 'Seeding Management'
    }
  }
}
</script>

<style lang="scss">
//// Customizations of Bootstrap
$theme-colors: (
    "primary": #00722d,
    "secondary": #00a02f,
    "tertiary": #6bb34b,
    "quarternary": #5c0c0c,
);
$enable-rounded: false; // corners of boxes

@import '~bootstrap';

body {
  overscroll-behavior-y: contain; // prevents "pull to reload" behaviour in PWA.
}
</style>
