<template>
  <div>

    <heading-main>3. Move to Seeds to Cracking</heading-main>
    <error-alert v-if="errorAlert">{{ errorAlert }}</error-alert>
    <div class="card mt-3">
      <div class="card-header">Ongoing Soakings:</div>
      <div class="card-body">
        <div v-for="soaking in soakings" :key="soaking.resourceId">
          <h6>Drum: {{ soaking.drumNo }}</h6>
          <span>Started on: {{ soaking.start }}</span>
          <p>{{ soaking.seeds }}</p>
          <router-link :to="{
                                   name: 'crack-seeds',
                                   params: {
                                       drumNo: soaking.drumNo,
                                   }
                                 }">
            <button class="btn btn-block btn-sm btn-secondary">
              Move Seeds to Cracking
            </button>
          </router-link>
          <hr>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'CrackSeeds',
  components: { ErrorAlert, HeadingMain },

  data () {
    return {
      soakings: [],
      errorAlert: null,
    }
  },

  created () {
    Backend.get('/api/ongoing-soakings')
        .then(response => {
          this.soakings = response.data.items
          this.errorAlert = null
        })
        .catch(error => {
          this.errorAlert = error.response.data.message
        })
  },
}
</script>

<style scoped>

</style>
