<template>
  <div>
    <div class="alert alert-danger" v-for="error in errors" :key="error">{{ error[0].trim('"') }}</div>
  </div>
</template>

<script>
export default {
  name: 'ValidationErrors',
  props: ['errors']
}
</script>

<style scoped>

</style>
