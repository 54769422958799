<template>
  <div>
    <heading-main>Pick Seeds for New Sowing</heading-main>
    <error-alert v-if="errorAlert">{{ errorAlert }}</error-alert>
    <validation-errors :errors="validationErrors"></validation-errors>
    <div class="d-flex w-100 justify-content-between">
      <p>Location:</p>
      <p>{{ crackingLocation }}</p>
    </div>
    <div class="form-group row">
      <label class="col-3 col-form-label" for="weight">Weight of Seeds:</label>
      <div class="col-9">
        <input class="col form-control text-right" id="weight" placeholder="input weight here" v-model="weight">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 col-form-label" for="sandbed">Sandbed:</label>
      <div class="col-9">
        <select class="form-control" id="sandbed" v-model="sandbedId">
          <option disabled value="">Please select sandbed for sowing ...</option>
          <option v-for="aSandbed in sandbeds" :value="aSandbed.resourceId" :key="aSandbed.resourceId">{{
              aSandbed.name
            }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-3 col-form-label" for="segment">Segment:</label>
      <div class="col-9">
        <select class="form-control" id="segment" v-model="segmentName">
          <option disabled value="">Please select segment in sandbed ...</option>
          <option v-for="segment in segments" :value="segment.name" :key="segment">{{ segment.name }}</option>
        </select>
      </div>
    </div>

    <button class="btn btn-block btn-primary" @click="sow">Register Sowing</button>

  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import ValidationErrors from '@/components/Utilities/ValidationErrors'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'NewSowing',
  components: { ErrorAlert, HeadingMain, ValidationErrors },
  props: {
    crackingLocation: String,
    crackingId: String,
  },
  data () {
    return {
      validationErrors: [],
      errorAlert: null,
      weight: null,
      sandbeds: [],
      sandbedId: null,
      segmentName: '',
    }
  },
  created () {
    Backend.get('/api/sandbeds')
        .then(response => {
          this.sandbeds = response.data.items
          this.errorAlert = null
          this.validationErrors = []
        })
        .catch(error => {
          this.errorAlert = error.response.data.message
          this.validationErrors = error.response.data.errors
        })
  },
  computed: {
    sandbed: function () {
      return this.sandbeds.filter((sandbed) => sandbed.resourceId === this.sandbedId)[0]
    },
    segments: function () {
      if (this.sandbed == null) {
        return null
      } else {
        return this.sandbed.segments
      }
    },
  },
  methods: {
    sow () {
      Backend.post('/api/sowings', {
        employeeNo: window.store.user.employeeNo,
        crackingId: this.crackingId,
        weightOfSeeds: this.weight,
        sandbedId: this.sandbedId,
        sandbedSegmentName: this.segmentName,
      })
          .then(() => {
            this.$router.push({ name: 'seeding-management', params: { message: 'Sowing registered.' } })
            this.errorAlert = null
            this.validationErrors = []
          })
          .catch(error => {
            this.errorAlert = error.response.data.message
            this.validationErrors = error.response.data.errors
          })
    }
  }
}
</script>

<style scoped>

</style>
