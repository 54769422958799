<template>
  <div>
    <success-alert class="mt-3" v-if="message">{{ message }}</success-alert>

    <gather-seeds></gather-seeds>
    <soak-seeds></soak-seeds>
    <crack-seeds></crack-seeds>
    <sow-seeds></sow-seeds>
    <end-sowings></end-sowings>

  </div>
</template>

<script>
import SuccessAlert from '@/components/Utilities/SuccessAlert'
import EndSowings from '@/views/Sowings/EndSowings'
import SowSeeds from '@/views/Sowings/SowSeeds'
import CrackSeeds from '@/views/Crackings/CrackSeeds'
import SoakSeeds from '@/views/Soakings/SoakSeeds'
import GatherSeeds from '@/views/Gatherings/GatherSeeds'

export default {
  name: 'SeedingManagement',
  props: ['message'],
  components: { EndSowings, SowSeeds, CrackSeeds, SoakSeeds, GatherSeeds, SuccessAlert }
}
</script>

<style scoped>

</style>
