<template>
  <div>
    <div class="mt-3">
      <router-link :to="{ name: 'sandbeds' }"><small>Back to Sandbeds Overview</small></router-link>
    </div>


    <heading-main class="mt-3">Sandbed: {{ name }}</heading-main>
    <error-alert v-if="errorAlertMessage">{{ errorAlertMessage }}</error-alert>
    <success-alert v-if="successAlertMessage">{{ successAlertMessage }}</success-alert>
    <div class="list-group">
      <div class="list-group-item" v-for="segment in segments" :key="segment">
        <div class="d-flex w-100 justify-content-between">
          <h6>Segment: {{ segment.name }}</h6>
          <button class="btn btn-danger" @click="deleteSegment(segment.name)">Delete</button>
        </div>
        <span>Seeds: {{ segment.seeds }}</span>
      </div>
    </div>


    <heading-main class="mt-3">Add Segment:</heading-main>
    <validation-errors :errors="validationErrors"></validation-errors>
    <div class="form-group">
      <label for="segmentName">Name of new segment:</label>
      <input class="form-control" id="segmentName" v-model="segmentName">
    </div>
    <button class="btn btn-block btn-secondary" @click="addSegment">Add Segment</button>

  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import ValidationErrors from '@/components/Utilities/ValidationErrors'
import SuccessAlert from '@/components/Utilities/SuccessAlert'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'Sandbed',
  components: { ErrorAlert, HeadingMain, ValidationErrors, SuccessAlert },

  data () {
    return {
      errorAlertMessage: null,
      name: '',
      resourceId: this.$route.params.id,
      segments: [],
      successAlertMessage: null,
      validationErrors: [],
      segmentName: '',
    }
  },

  created () {
    Backend.get('/api/sandbeds/' + this.resourceId)
        .then(response => {
          this.errorAlertMessage = null
          this.name = response.data.name
          this.segments = response.data.segments
        })
        .catch(error => {
          this.errorAlertMessage = error.response.data.message
        })
    this.$root.headerText = 'Manage Sandbed'
  },

  methods: {
    addSegment: function () {
      Backend.post('/api/sandbeds/' + this.resourceId + '/segments', {
        name: this.segmentName
      })
          .then(response => {
            this.errorAlertMessage = null
            this.name = response.data.name
            this.segments = response.data.segments
            this.successAlertMessage = 'Segment added!'
            this.validationErrors = []
            this.segmentName = ''
          })
          .catch(error => {
            this.errorAlertMessage = error.response.data.message
            this.successAlertMessage = null
            this.validationErrors = error.response.data.errors
            this.segmentName = ''
          })
    },
    deleteSegment: function (segmentName) {
      Backend.delete('/api/sandbeds/' + this.resourceId + '/segments/' + segmentName)
          .then(response => {
            this.errorAlertMessage = null
            this.successAlertMessage = 'Segment Deleted!'
            this.segments = response.data.segments
          })
          .catch(error => {
            this.errorAlertMessage = error.response.data.message
            this.successAlertMessage = null
            this.validationErrors = error.response.data.errors
            this.segmentName = ''
          })
    }
  }
}
</script>

<style scoped>

</style>
