<template>
  <div>
    <heading-main>Purchase Batch</heading-main>
    <validation-errors :errors="validationErrors"></validation-errors>
    <error-alert v-if="errorAlert">{{ errorAlert }}</error-alert>
    <div class="d-flex w-100 justify-content-between">
      <p>Batch No:</p>
      <p>{{ batchNo }}</p>
    </div>
    <div class="d-flex w-100 justify-content-between">
      <p>Farmer:</p>
      <p>{{ farmerCode }}</p>
    </div>
    <div class="d-flex w-100 justify-content-between">
      <p>Weight:</p>
      <p>{{ weight }}</p>
    </div>
    <div class="form-group row">
      <label class="col-3 col-form-label" for="price">Price:</label>
      <div class="col-9">
        <input class="col form-control text-right" id="price" placeholder="input price here" v-model="price">
      </div>
    </div>
    <button class="btn btn-block btn-primary" @click="purchase">Make Purchase</button>
  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import ValidationErrors from '@/components/Utilities/ValidationErrors'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'NewPurchase',
  components: { ErrorAlert, HeadingMain, ValidationErrors },
  props: {
    batchNo: String,
    farmerCode: String,
    weight: String
  },
  data () {
    return {
      price: '',
      validationErrors: [],
      errorAlert: null,
    }
  },
  methods: {
    purchase () {
      Backend.post('/api/purchases', {
        employeeNo: window.store.user.employeeNo,
        seedsBatchId: this.batchNo,
        farmerCode: this.farmerCode,
        price: this.price
      })
          .then(() => {
            this.$router.push({ name: 'seeding-management', params: { message: 'Seeds successfully purchased.' } })
          })
          .catch(error => {
            this.errorAlert = error.response.data.message
            this.validationErrors = error.response.data.errors
          })
    }
  }
}
</script>

<style scoped>

</style>
