<template>
  <div>

    <heading-main>Soak Crate in Drum</heading-main>
    <error-alert v-if="errorAlert">{{ errorAlert }}</error-alert>
    <validation-errors :errors="validationErrors"></validation-errors>
    <div class="d-flex w-100 justify-content-between">
      <p>Crate:</p>
      <p>{{ crateTracker }}</p>
    </div>
    <div class="form-group row">
      <label class="col-3 col-form-label" for="drumNo">DrumNo:</label>
      <div class="col-9">
        <input class="col form-control text-right" id="drumNo" placeholder="input drum number here" v-model="drumNo"
               type="number">
      </div>
    </div>
    <button class="btn btn-block btn-primary" @click="soak">Register Soaking</button>

  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import ValidationErrors from '@/components/Utilities/ValidationErrors'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'NewSoaking',
  components: { ErrorAlert, HeadingMain, ValidationErrors },

  props: {
    crateTracker: String,
  },
  data () {
    return {
      drumNo: '',
      validationErrors: [],
      errorAlert: null,
    }
  },
  methods: {
    soak () {
      Backend.post('/api/soakings', {
        employeeNo: window.store.user.employeeNo,
        drumNo: this.drumNo,
        crateTracker: this.crateTracker,
      })
          .then(() => {
            this.$router.push({ name: 'seeding-management', params: { message: 'Soaking registered.' } })
          })
          .catch(error => {
            this.errorAlert = error.response.data.message
            this.validationErrors = error.response.data.errors
          })
    }
  }
}
</script>

<style scoped>

</style>
