<template>
  <div>
    <heading-main>Gather new seeds</heading-main>
    <validation-errors :errors="validationErrors"></validation-errors>
    <error-alert v-if="errorAlert">{{ errorAlert }}</error-alert>

    <div class="form-group">
      <label for="crate">Barcode of Crate:</label>
      <input class="form-control" id="crate" v-model="crateBarcode">
    </div>

    <div class="alert alert-light" v-if="currentCrate">
      <span>Current seeds in this crate: </span>
      <span>{{ currentCrate.seeds }}</span>
    </div>

    <div class="form-group">
      <label for="weight">Weight of seeds:</label>
      <div class="input-group">
        <input class="form-control" id="weight" type="number" v-model="weight">
        <div class="input-group-append"><span class="input-group-text">grams</span></div>
      </div>
    </div>
    <div class="form-group">
      <label for="motherTree">Mother tree:</label>
      <select class="form-control" id="motherTree" v-model="motherTreeId">
        <option selected disabled>Select tree from which seeds are gathered</option>
        <option v-for="tree in motherTrees" :value="tree.resourceId" :key="tree.resourceId">{{ tree.farmerCode }} Tree
          No.:{{
            tree.number
          }}
        </option>
      </select>
    </div>

    <button class="btn btn-block btn-secondary mt-3" @click="gatherSeeds">Complete</button>

  </div>
</template>

<script>
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import ValidationErrors from '@/components/Utilities/ValidationErrors'
import { Backend } from '@/utilities/Backend'

export default {
  name: 'NewGathering',
  components: { ErrorAlert, HeadingMain, ValidationErrors },

  data () {
    return {
      crateBarcode: '',
      currentCrate: null,
      weight: '',
      motherTrees: [],
      motherTreeId: '',
      validationErrors: [],
      errorAlert: null
    }
  },

  watch: {
    crateBarcode: function () {
      this.getCrate()
    }
  },

  created () {
    Backend.get('/api/mother-trees')
        .then(response => {
          this.motherTrees = response.data.items
        })
        .catch(error => {
          this.errorAlert = 'Failed to load mother trees. ' + error.response.data.message
        })
  },

  methods: {
    getCrate: function () {
      if (this.crateBarcode.length === 8) {
        Backend.get('/api/crates/' + this.crateBarcode)
            .then(response => {
              this.currentCrate = response.data
              this.errorAlert = null
            })
            .catch(() => {
              this.errorAlert = 'No crate with this barcode registered.'
              this.currentCrate = null
              this.crateBarcode = ''
            })
      } else {
        this.currentCrate = null
      }
    },
    gatherSeeds: function () {
      Backend.post('/api/gatherings', {
        employeeNo: window.store.user.employeeNo,
        crate: this.crateBarcode,
        weight: this.weight,
        motherTreeId: this.motherTreeId
      })
          .then(() => {
            this.$router.push({ name: 'seeding-management', params: { message: 'Seeds successfully registered.' } })
          })
          .catch(error => {
            this.errorAlert = error.response.data.message
            this.validationErrors = error.response.data.errors
          })
    }
  }
}
</script>

<style scoped>

</style>
