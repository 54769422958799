import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Authentication/Login'
import Logout from '@/views/Authentication/Logout'
import SeedingManagement from '@/views/SeedingManagement/SeedingManagement'
import NewGathering from '@/views/Gatherings/NewGathering'
import NewPurchase from '@/views/Purchases/NewPurchase'
import NewSoaking from '@/views/Soakings/NewSoaking'
import NewCracking from '@/views/Crackings/NewCracking'
import NewSowing from '@/views/Sowings/NewSowing'
import NewDiscard from '@/views/Discards/NewDiscard'
import Crates from '@/views/Crates/Crates'
import MotherTrees from '@/views/MotherTrees/MotherTrees'
import Sandbeds from '@/views/Sandbeds/Sandbeds'
import Sandbed from '@/views/Sandbeds/Sandbed'
import NotFound from '@/components/StatusPages/404'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/seeding-management'
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    props: true
  },
  {
    path: '/logout',
    component: Logout,
    name: 'Logout'
  },
  {
    path: '/seeding-management',
    component: SeedingManagement,
    name: 'seeding-management',
    props: true
  },
  {
    path: '/seeding-management/gatherings/create',
    component: NewGathering,
    name: 'gather-new-seeds',
  },
  {
    path: '/seeding-management/purchases/create/:batchNo/:farmerCode/:weight',
    component: NewPurchase,
    name: 'purchase-batch',
    props: true,
  },
  {
    path: '/seeding-management/soakings/create/:crateTracker',
    component: NewSoaking,
    name: 'soak-crate',
    props: true,
  },
  {
    path: '/seeding-management/crackings/create/:drumNo',
    component: NewCracking,
    name: 'crack-seeds',
    props: true,
  },
  {
    path: '/seeding-management/sowings/create/:crackingLocation/:crackingId',
    component: NewSowing,
    name: 'sow-seeds',
    props: true,
  },
  {
    path: '/seeding-management/discards/create/:crackingLocation/:crackingId',
    component: NewDiscard,
    name: 'discard-seeds',
    props: true,
  },
  {
    path: '/crates/create',
    component: Crates,
    name: 'crates',
  },
  {
    path: '/mother-trees/create',
    component: MotherTrees,
    name: 'mother-trees',
  },
  {
    path: '/sandbeds/create',
    component: Sandbeds,
    name: 'sandbeds',
  },
  {
    path: '/sandbeds/:id',
    component: Sandbed,
    name: 'sandbed',
  },
  {
    path: '/:pathMatch(.*)',
    component: NotFound,
    name: '404',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Navigation Guard
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !window.store.auth.isAuthenticated) next({ name: 'Login' })
  else next()
})

export default router
