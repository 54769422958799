<template>
    <div>
        <div class="text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <p class="ml-3"><slot></slot></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LoadingSpinner"
    }
</script>

<style scoped>

</style>
