<template>
  <div id="registerCrate">

    <heading-main>Register a new crate</heading-main>

    <input id="crate" name="crate" class="form-control" placeholder="Scan code here" v-model="newTracker" autofocus>
    <small class="text-muted pl-2">You can also use the keyboard. It will register automatically.</small>
    <label for="crate"></label>

    <success-alert v-if="showRegistrationSuccess">Crate registered!</success-alert>
    <error-alert v-if="showRegistrationError">{{ registrationErrorMessage }}</error-alert>
    <validation-errors :errors="validationErrors"></validation-errors>


    <heading-main>Registered Crates:</heading-main>
    <loading-spinner v-if="showLoadingCrates">Loading crates...</loading-spinner>
    <error-alert v-if="showCrateLoadingError">{{ crateLoadingErrorMessage }}</error-alert>
    <ul class="list-group" v-for="crate in crates" :key="crate.tracker">
      <li class="list-group-item">
        <h6>Crate: {{ crate.tracker }}</h6>
        Current content: {{ crate.seeds }}
      </li>
    </ul>

  </div>
</template>

<script>
import LoadingSpinner from '@/components/Utilities/LoadingSpinner'
import ErrorAlert from '@/components/Utilities/ErrorAlert'
import HeadingMain from '@/components/Utilities/HeadingMain'
import ValidationErrors from '@/components/Utilities/ValidationErrors'
import { Backend } from '@/utilities/Backend'
import SuccessAlert from '@/components/Utilities/SuccessAlert'

export default {
  name: 'Crates',
  components: { ErrorAlert, HeadingMain, ValidationErrors, LoadingSpinner, SuccessAlert },
  data () {
    return {
      newTracker: '',
      showRegistrationSuccess: false,
      showRegistrationError: false,
      registrationErrorMessage: '',
      validationErrors: [],
      showLoadingCrates: true,
      showCrateLoadingError: false,
      crateLoadingErrorMessage: '',
      crates: []
    }
  },

  watch: {
    newTracker: function () {
      this.newCrate()
    }
  },

  created () {
    Backend.get('/api/crates')
        .then(response => {
          this.showLoadingCrates = false
          this.crates = response.data.items
        })
        .catch(error => {
          this.showLoadingCrates = false
          this.showCrateLoadingError = true
          this.crateLoadingErrorMessage = error.response.data.message
          this.crates = []
        })
    this.$root.headerText = 'Manage Crates'
  },

  methods: {
    newCrate: function () {
      if (this.newTracker.length === 8) {
        Backend.post('/api/crates', {
          tracker: this.newTracker
        })
            .then(response => {
              this.newTracker = ''
              this.showRegistrationSuccess = true
              this.showRegistrationError = false
              this.registrationErrorMessage = ''
              this.validationErrors = []
              this.crates = response.data.items
            })
            .catch(error => {
              this.newTracker = ''
              this.showRegistrationSuccess = false
              this.showRegistrationError = true
              this.registrationErrorMessage = error.response.data.message
              this.validationErrors = error.response.data.errors
            })
      } else if (this.newTracker.length > 8) {
        this.newTracker = ''
        this.showRegistrationSuccess = false
        this.showRegistrationError = true
        this.registrationErrorMessage = 'Barcode too long.'
      }
    }
  }

}
</script>

<style scoped>

</style>
