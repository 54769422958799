<template>

    <div class="alert alert-success"><slot></slot></div>

</template>

<script>
    export default {
        name: "SuccessAlert",
    }
</script>

<style scoped>

</style>
